@mixin search($min-width: $search-min-width) {
  position: relative;
  min-width: $min-width;

  .fa-search {
    position: absolute;
    top: calc(50% - ($font-size-base * 0.5));
    left: $input-padding-x;
    display: inline-block;
    color: $input-placeholder-color;

    &:hover,
    &:focus {
      color: $input-plaintext-color;
    }
  }

  .form-control {
    width: $min-width;
    padding-left: (($input-padding-x * 2) + $font-size-base);
    @include transition($transition-base);

    &:hover,
    &:focus {
    }
  }
}

.search {
  @include search();
}

.searchBar {
  @include media-breakpoint-between(md, xxl) {
    margin-bottom: 1rem !important;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 0rem !important;
  }
}
