// scss-docs-start ellipsis-mixins
@mixin ellipsis-v {
  [class*='ellipsis-v'] {
  }
}

@mixin ellipsis-h {
  [class*='ellipsis-v'] {
  }
}

@mixin ellipsis($direction: vertical) {
  @if $enable-ellipsis {
    [class*='svg-inline'] {
      vertical-align: -0.125em;
    }
    @if $direction == vertical {
      @include ellipsis-v();
    } @else if $direction == horizontal {
      @include ellipsis-h();
    }
  }
}
// scss-docs-end ellipsis-mixins
