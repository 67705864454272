.form-outline {
  // necessary due to intentional switch to proprietary `form-group` instead of MDB's `form outline`
  .select-input {
    padding-top: $input-padding-top;
    padding-bottom: $input-padding-bottom;
  }
}
.select-fake-value {
  display: none !important;
}
.select-options-wrapper {
  .form-check-input + .select-option-secondary-text {
    margin-left: 26px;
  }
}
