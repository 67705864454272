.helper {
  position: absolute;
  top: 0;
  width: auto;
  height: auto;
  padding: $table-cell-padding-y $table-cell-padding-x;
  font-family: $font-family-base;
  font-size: $font-size-base;
  display: inline-block;
}
#widthHelper {
  white-space: nowrap;
}
