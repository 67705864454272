// guide box
#guideBox {
  position: absolute;
  background-color: #2980b9;
  color: $white;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 15px 25px 25px 25px;
  box-shadow:
    0px 20px 24px rgba(0, 0, 0, 0.1),
    0px 12px 12px rgba(0, 0, 0, 0.04),
    0px 4px 24px rgba(0, 0, 0, 0.04);
  line-height: 1.5;
  max-width: 500px;
  z-index: 1000;
  text-align: justify;

  .pointer {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #2980b9;
    top: -12px;
    left: calc(50% - 6px);
    position: absolute;
  }
  /* #guideBox.pointer.left::before {
	left: 61px;
} */
  .title {
    text-align: center;
    padding-bottom: 10px;
    text-decoration: underline;
  }
  .close {
    float: left;
    margin-top: 25px;
  }
  .prev,
  .next {
    float: right;
    margin-top: 25px;
    padding: 5px 15px;
    height: auto;
    background-color: $white;
  }
  .btn {
    padding: 5px 15px;
    height: auto;
    background-color: $white;
  }
}
.iconNumber {
  background-color: $danger;
  border-radius: 50%;
  color: white;
  display: none;
  margin-left: -16px;
  font-size: 12px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  right: -4px;
  top: -4px;
  padding: 0 4px;
}
#guideBtn .iconNumber {
  display: inline-block;
}
.marked {
  border: 2px solid red !important;
}
.confirm .ui-icon {
  float: left;
}
.confirm .text {
  margin-left: 20px;
}

/* context menu */
#contextMenu {
  max-width: 370px;
  position: absolute;
  z-index: 100000;
  background: $white;
  color: #333;
  box-shadow: 1px 1px 10px #888;

  div {
    padding: 3px 15px;
    cursor: pointer;
  }
  div:not(.contextSub):hover,
  div.active {
    color: #16181b;
    background-color: #eee;
  }
  div div {
    display: none;
  }
}

/* settings */
.settings {
  * {
    float: left;
  }
  .label {
    width: 150px;
    margin-bottom: 3px;
    border: none;
  }
  .image {
    display: list-item;
    list-style-type: disc;
    height: 31px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: -5px;
  }
  .image.open {
    list-style-type: circle;
  }
  .break {
    clear: left;
  }
  .levelblock {
    display: none;
    margin-left: 20px;
    width: 100%;
  }
  .array {
    border: 1px dotted black;
  }
  .value {
    width: calc(100% - 340px);
  }
}

.iconBox {
  display: inline-block;
  text-align: center;
  border: 1px solid gray;
  width: 70px;
  vertical-align: top;
  height: 50px;
  overflow: hidden;
  padding: 5px;
}
.iconBox span {
  display: block;
}
