header {
  position: relative;
  z-index: $zindex-fixed;

  .nav-item {
    margin: 0 map-get($spacers, 1);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-link {
    position: relative;
    width: $navbar-nav-icon-height;
    height: $navbar-nav-icon-height;
    padding: 0;
    line-height: $navbar-nav-icon-height;
    text-align: center;
    cursor: pointer;
    background-color: $white;
    @include border-radius(50%);

    @include media-breakpoint-down(lg) {
      width: 35px;
    }

    &:hover,
    &:focus {
      background-color: $gray-100;
    }

    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  .badge {
    &.top-0 {
      top: map-get($spacers, 2) !important;
    }

    &.start-100 {
      left: calc(100% - #{map-get($spacers, 2)}) !important;
    }
  }

  .progress {
    margin-right: 10px;
    width: 150px;
    cursor: pointer;
  }

  .search {
    .fa-search {
      color: $gray-300;
    }

    &:hover,
    &:focus {
      .fa-search {
        color: $gray-400;
      }
    }

    .form-control {
      background-color: $form-search-bg;

      &::placeholder {
        color: $gray-300;
      }

      &:hover,
      &:focus {
        color: $body-color;
        background-color: $gray-50;

        //border-color: $gray-50;
        //box-shadow: inset 0px 0px 0px 0px transparent !important;
        &::placeholder {
          color: $gray-400;
        }
      }
    }
  }

  .search {
    margin-right: map-get($spacers, 3);

    .form-control {
      @include media-breakpoint-only(lg) {
        max-width: 180px;
      }
    }
  }
}

.navbar-start {
  .left-icon_toggle {
    width: 31px;
    margin-right: 0;
    margin-left: 15px;
    height: 26px;
    z-index: 123;
    padding: 0 0 0 0px;
    font-size: 20px;
    line-height: 26px;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    span {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 2px;
      background: $gray-500;
      margin: 2px 0;
      border-radius: 0;
      flex-direction: row;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #headerLeftButtons {
    @include media-breakpoint-down(lg) {
      margin: 0 !important;
      position: fixed;
      left: 0;
      top: 55px;
      background: $white;
      flex-wrap: wrap;
      display: flex;
      width: 240px;
      transform: translateX(-300px);
      transition: $ease300;
    }

    &.show {
      transform: translateX(0);
    }

    li {
      margin: 0;

      a {
        @include media-breakpoint-down(lg) {
          padding: 10px 0px !important;
          background: transparent;
          height: auto;
          line-height: normal;
          border-radius: 0;
          margin: 0 5px;
        }
      }
    }
  }
}

.navbar-end {
  .search {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      display: flex;
      justify-content: flex-end;
      max-width: 140px;
      margin-right: 0px;
      height: 32px;
      position: relative;
      z-index: 123;
    }

    .fa-search {
      @include media-breakpoint-down(md) {
        color: $gray-500;
        transition: $ease200;
        position: absolute;
        left: auto;
        right: 4px;
        z-index: 12;
      }
    }

    input.form-control {
      @include media-breakpoint-down(md) {
        width: 0;
        padding-right: 2.5rem;
        padding-left: 10px;
        background-color: transparent;
        z-index: 123;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        height: 32px;
      }

      &:focus {
        @include media-breakpoint-down(md) {
          width: 140px;
          max-width: 140px;
          opacity: 1;
          z-index: 1;
          background-color: $gray-50;
        }
      }
    }
  }

  .notification-icon {
    margin: 0 0 0 15px;
    padding: 0px 0 0;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    a {
      background: transparent !important;
      width: auto;
      height: auto;
      line-height: normal;
    }

    .icon {
      font-size: 15px;
    }
  }

  .icons_toggle-btn {
    width: 21px;
    margin-right: 15px;
    margin-left: 15px;
    height: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    z-index: 123;
    padding: 0;
    justify-content: center;
    font-size: 20px;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    span {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 2px;
      background: $gray-500;
      margin: 2px 0;
      border-radius: 0;
      flex-direction: row;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #headerRightButtons {
    @include media-breakpoint-down(sm) {
      margin: 0 15px 0 0 !important;
    }

    li {
      @include media-breakpoint-down(md) {
        margin: 0;
      }

      @include media-breakpoint-down(lg) {
        #tabBtn,
        #refreshBtn,
        #chatBtn {
          display: none;
        }
      }

      a {
        @include media-breakpoint-down(sm) {
          padding: 0px !important;
          background: transparent;
          height: auto;
          line-height: normal;
          border-radius: 0;
          margin: 0;
        }
      }
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-down(sm) {
    padding: 0rem 0 0;

    .user-content {
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
    }

    .user-info {
      display: flex;
      justify-content: center;

      h5 {
        margin-bottom: 0;
      }

      > * {
        @include text-truncate();
      }
    }

    .nav-pills {
      .nav-link {
        font-weight: normal;
      }
    }
  }
}

.navbar-start {
  @include media-breakpoint-up(sm) {
    margin-right: auto;
  }

  .navbar-brand {
    @include media-breakpoint-down(md) {
      margin-right: 0 !important;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0 !important;
      width: 35px;
      margin-right: 0;
    }

    img {
      @include media-breakpoint-down(sm) {
        width: 170px;
        max-width: none;
        min-width: 170px;
      }
    }
  }

  .navbar-nav {
    li {
      &.nav-item {
        margin: 0 2px;

        a {
          @include media-breakpoint-down(sm) {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

header.navbar {
  #logo {
    @include media-breakpoint-down(md) {
      margin-right: 10px;
    }
  }
}

.nav {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
  }
}

.toggle-btn {
  width: 24px;
  margin-right: 10px;
  margin-left: 6px;
  cursor: pointer;
  height: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  z-index: 123;
  position: relative;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  span {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 2px;
    background: $gray-500;
    margin: 2px 0;
    border-radius: 20px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.navbar-end {
  .search {
    @include media-breakpoint-up(md) {
      margin-right: map-get($spacers, 2);
    }

    .form-control {
      @include media-breakpoint-only(md) {
        max-width: 180px;
      }
    }
  }
}

.overlay-layer {
  header {
    z-index: 99999;
  }
}

.show {
  header {
    z-index: 99999;
  }
}
