// Navs

//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width $border-style $nav-tabs-border-color;

  .nav-link {
    text-transform: none; // necessary due to hard-coded text-transform variable in MDB nav

    &:hover,
    &:focus {
      border-color: $nav-tabs-link-hover-border-color;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: transparent;

    &:hover,
    &:focus {
      color: $nav-tabs-link-active-color;
      border-color: $primary;
    }
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    text-transform: none; // necessary due to hard-coded text-transform variable in MDB nav
  }
}
