.table {
  --#{$prefix}table-border-color: #{$border-color-translucent};
  margin-bottom: 0;
  width: 100%;

  .table-small-show {
    display: none;
  }

  .table-big-show {
    display: table-cell;
  }

  .row-select {
    width: $form-check-input-width;
    padding: 0;

    .form-check {
      padding: 1rem 1.4rem;
      cursor: pointer;
    }

    .form-check-input {
      &[type='checkbox'] {
        margin-right: 0;
        margin-left: 0;
        float: none;
      }
    }
  }

  tr.text-decoration-line-through span,
  tr.text-decoration-line-through p {
    text-decoration: line-through !important;
  }

  td.image img {
    max-width: 100px;
    max-height: 100px;
    @extend .img-thumbnail;
  }

  td .pdf {
    max-width: 15vw;
    max-height: 15vh;
  }

  td > p,
  td > span {
    max-height: 150px;
    overflow: hidden;
    display: inline-block;
  }
}

.view-overview.view-small .table {
  th,
  td {
    &[scope='row'] {
      display: table-cell !important;
    }

    &:not(.table-small-show) {
      display: none;
    }

    &.table-small-show {
      display: table-cell;
    }
  }
}

.table-scroll {
  $unknown: 180px !default;
  max-width: 100%;
  max-height: 1000px;

  thead,
  tfoot {
    th,
    td {
      position: sticky;
      z-index: 3;
      box-shadow: var(--datatable-border-color) 0 1px;
      background-color: $white;
    }
  }

  thead {
    th {
      top: 0;

      span {
        position: relative;

        .iconSort {
          position: absolute;
          right: -13px;
        }
      }
    }

    &:hover th:not(.row-select) {
      border-right: 1px dotted lightgray;
      padding-right: calc(1.4rem - 1px);
    }
  }

  tfoot {
    th,
    td {
      bottom: 0;
      white-space: nowrap;
    }
  }
}

.modal {
  .table-scroll {
    max-height: calc(60vh);

    @media screen and (max-width: 1900px) {
      max-width: 70vw;
    }

    @media screen and (max-width: 1100px) {
      max-width: 65vw;
    }

    @media screen and (max-width: 920px) {
      max-width: 60vw;
    }

    @media screen and (max-width: 780px) {
      max-width: 55vw;
    }

    @media screen and (max-width: 697px) {
      max-width: 50vw;
    }
  }
}

.dropdown-search {
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: $dropdown-item-padding-y $dropdown-item-padding-x;
}

table {
  &[border='1'] td {
    border-width: 1px;
  }
  &.dashboard-table {
    width: 100%;
  }
}

.table {
  .table-active {
    background-color: rgba(210, 216, 217, 0.3);
  }
}

.table-hover {
  tbody {
    tr:hover {
      background-color: rgba(210, 216, 217, 0.3);
    }
  }
}
