.wysiwyg {
  color: black;
  font-size: 13px;
  font-family: Helvetica, serif;

  page,
  .mce-pagebreak {
    margin: 50px -10px;
    display: block;
    border-top: 3px dashed black;
    height: 1px;
    width: 100%;
  }
  div {
    margin-left: 0.1px;
  }
  a {
    color: black;
  }
  li {
    padding: 5px 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 5px 0;
    margin: 0;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 15px;
  }
  table {
    thead th {
      font-weight: normal;
      padding: 3px;
      border-bottom: 1px solid gray;
      vertical-align: bottom;
      text-align: left;
    }

    td {
      padding: 3px;
      vertical-align: top;
      min-width: 20px;
    }

    &[border='1'] td {
      border: 1px solid gray !important;
    }
    &.text td:first-child {
      padding-right: 25px;
      padding-bottom: 5px;
    }
    &.tableDynamic {
      margin-bottom: 15px;

      thead tr th {
        background-color: #cecece;
      }

      tbody tr td {
        border-bottom: 1px solid #f0f0f0;
      }
    }

    .borderTop {
      border-top: 1px solid gray !important;
    }
    .borderBottom {
      border-bottom: 1px solid gray !important;
    }

    table {
      width: auto;

      td.wysiwyg-font-size-12 table td {
        text-align: right;
        padding: 2px 0 2px 0;
      }
    }
    img {
      max-width: initial;
    }
  }

  .var {
    border: none;
    font-size: 100%;
    font-family: 'Roboto', serif;
    background-color: lightgray;
    border-radius: 2px;
    margin: 0 1px;
    padding: 0 3px;
    color: inherit;
    font-weight: inherit;
    text-decoration: inherit;
  }

  .wysiwyg-paragraph {
    padding: 5px 0;
    margin: 0;
    display: block;
  }
  .wysiwyg-text-align-left {
    text-align: left;
  }
  .wysiwyg-text-align-center {
    text-align: center;
  }
  .wysiwyg-text-align-right {
    text-align: right;
  }
  .wysiwyg-font-size-8 {
    font-size: 8px;
  }
  .wysiwyg-font-size-10 {
    font-size: 10px;
  }
  .wysiwyg-font-size-11 {
    font-size: 11px;
  }
  .wysiwyg-font-size-12 {
    font-size: 12px;
  }
  .wysiwyg-font-size-13 {
    font-size: 13px;
  }
  .wysiwyg-font-size-14 {
    font-size: 14px;
  }
  .wysiwyg-font-size-16 {
    font-size: 16px;
  }
  .wysiwyg-font-size-18 {
    font-size: 18px;
  }
  .wysiwyg-font-size-20 {
    font-size: 20px;
  }
}
.tox-tinymce {
  .tox-promotion {
    display: none;
  }
  .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid lightgray !important;
  }
}
.tox-notification,
.tox-notifications-container {
  display: none !important;
}
