// Toggle

@mixin action-toggle($action-toggle-padding-y, $action-toggle-padding-x) {
  --#{$app-prefix}action-toggle-padding-y: #{$action-toggle-padding-y};
  --#{$app-prefix}action-toggle-padding-x: #{$action-toggle-padding-x};
  --#{$app-prefix}action-toggle-color: #{$action-toggle-color};
  --#{$app-prefix}action-toggle-bg: #{$action-toggle-bg};
  --#{$app-prefix}action-toggle-hover-color: #{$action-toggle-hover-color};
  --#{$app-prefix}action-toggle-hover-bg: #{$action-toggle-hover-bg};
  --#{$app-prefix}action-toggle-focus-color: #{$action-toggle-focus-color};
  --#{$app-prefix}action-toggle-focus-bg: #{$action-toggle-focus-bg};
  --#{$app-prefix}acction-toggle-border-radius: #{$action-toggle-border-radius};

  padding: var(--#{$app-prefix}action-toggle-padding-y)
    var(--#{$app-prefix}action-toggle-padding-x);
  color: var(--#{$app-prefix}action-toggle-color);
  @include border-radius(var(--#{$app-prefix}acction-toggle-border-radius));
  @include gradient-bg(var(--#{$app-prefix}action-toggle-bg));

  &:hover {
    color: var(--#{$app-prefix}action-toggle-hover-color);
    background-color: var(--#{$app-prefix}action-toggle-hover-bg);
  }

  &:focus {
    color: var(--#{$app-prefix}action-toggle-focus-color);
    background-color: var(--#{$app-prefix}action-toggle-focus-bg);
  }

  @include ellipsis();

  @if $enable-caret {
    &::after {
      display: none;
    }
  }
}
