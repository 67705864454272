.field-steps {
  text-align: center;

  .btn {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: $text-muted;
    text-align: left;
    white-space: nowrap;
    border: 0 !important;
    @include box-shadow(none);

    &:hover,
    &:focus {
      background-color: transparent;
    }

    &::before {
      display: inline-block;
      width: $steps-size;
      height: $steps-size;
      margin-right: $steps-margin-x;
      background-color: $text-muted;
      font-weight: $font-weight-bold;
      line-height: $steps-size;
      color: $white;
      text-align: center;
      @include border-radius($border-radius-pill);
    }

    &::after {
      display: inline-block;
      width: $steps-size * 1.5;
      margin-bottom: map-get($spacers, 1);
      margin-left: $steps-margin-x;
      content: '';
      border-bottom: $border-width $border-style $border-color;
    }

    &.success::before {
      background-color: $success;
    }

    &.warning::before {
      background-color: $warning;
    }

    &.danger::before {
      background-color: $danger;
    }

    &:nth-child(1)::before {
      content: '1';
    }

    &:nth-child(2)::before {
      content: '2';
    }

    &:nth-child(3)::before {
      content: '3';
    }

    &:nth-child(4)::before {
      content: '4';
    }

    &:nth-child(5)::before {
      content: '5';
    }

    &:nth-child(6)::before {
      content: '6';
    }

    &:nth-child(7)::before {
      content: '7';
    }

    &:nth-child(8)::before {
      content: '8';
    }

    &:nth-child(9)::before {
      content: '9';
    }

    &:nth-child(10)::before {
      content: '10';
    }

    &:nth-child(11)::before {
      content: '11';
    }

    &:nth-child(12)::before {
      content: '12';
    }

    &:nth-child(13)::before {
      content: '13';
    }

    &:nth-child(14)::before {
      content: '14';
    }

    &:nth-child(15)::before {
      content: '15';
    }

    &:last-child::after {
      width: 0;
    }

    + .btn {
      margin-left: $steps-margin-x;
    }
  }

  &.smaller1 .btn:not(:last-child)::after {
    width: 10px;
  }

  &.smaller2 .btn:not(:last-child)::after {
    width: 0;
  }

  &.smaller3 .btn {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
