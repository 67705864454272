@mixin sidebar-collapse() {
  width: $sidebar-mini-width;
  padding: map-get($spacers, 3) 0;
  @include transition($transition-base);
  //@include transition(all 2s ease-in-out);

  .store-content {
    padding: 0 map-get($spacers, 2);

    > a {
      gap: 0;
      justify-content: center;
    }

    .dropdown-toggle {
      &::after {
        display: none;
        opacity: 0;
      }
    }

    .avatar {
      --#{$app-prefix}avatar-size: #{$avatar-size-sm};

      .avatar-title {
        @include font-size($font-size-sm);
      }
    }

    .store-info {
      display: none;
      opacity: 0;
    }
  }

  .nav-link {
    --#{$prefix}nav-pills-link-border-radius: 0;

    .icon {
      margin-right: 0;
    }
  }

  .link-title {
    display: none;
    opacity: 0;
    @include text-truncate();
  }

  .nav-item .collapse {
    display: none;

    &.show {
      display: block;
    }
  }

  .search {
    padding: 0 map-get($spacers, 2);

    .fa-search {
      left: $input-padding-x * 1.5;
    }

    .form-control {
      padding-left: 6px;
      padding-right: 6px;

      &::placeholder {
        color: transparent;
      }
    }
  }
}

@mixin sidebar-expand() {
  width: $sidebar-width;
  padding: map-get($spacers, 3);
  @include transition($transition-base);
  //@include transition(all 2s ease-in-out);

  .store-content {
    padding: 0;

    > a {
      justify-content: flex-start;
      gap: map-get($spacers, 3);
    }

    &.dropdown {
      .dropdown-toggle {
        &::after {
          display: flex;
          opacity: 1;
        }
      }

      .store-info {
        max-width: calc(
          $sidebar-width - $avatar-size-md * 1.2 - (map-get($spacers, 3) * 3)
        );
      }
    }

    &:not(.dropdown) {
      .store-info {
        max-width: calc(
          $sidebar-width - map-get($spacers, 3) - ($avatar-size-md * 1.2) - map-get(
              $spacers,
              3
            )
        );
      }
    }

    .avatar {
      --#{$app-prefix}avatar-size: #{$avatar-size-md * 1.2};
      @include transition($transition-base);

      //@include transition(all 2s ease-in-out);
      @include media-breakpoint-down(lg) {
        --#{$app-prefix}avatar-size: #{$avatar-size-sm};
      }
    }

    .store-info {
      display: flex;
      justify-content: space-evenly;
      opacity: 1;

      small:empty {
        display: none;
      }
    }
  }

  .nav-link {
    --#{$prefix}nav-pills-link-border-radius: #{$nav-pills-link-border-radius};

    .icon {
      margin-right: map-get($spacers, 3);
    }
  }

  .link-title {
    display: inline-flex;
    opacity: 1;
  }

  #menu-cont {
    max-height: calc(100% - 48px);
  }

  #menu {
    width: 225px;
  }

  .nav-item .collapse {
  }

  .search {
    padding: 0 1rem 0 0;

    .fa-search {
      left: $input-padding-x;
    }

    .form-control {
      padding-left: (($input-padding-x * 2) + $font-size-base);

      &::placeholder {
        color: $gray-200;
      }
    }
  }
}

.sidebar {
  @include sidebar-expand();
  padding-right: 0;

  .store-content {
    > a {
      display: flex;
    }
  }

  .avatar {
    @include store-avatar();
  }

  .store-info {
    justify-content: center;

    h5 {
      margin-bottom: 0;
    }

    > * {
      @include text-truncate();
    }
  }

  .dropdown-toggle {
    position: relative;

    &::after {
      position: absolute;
      right: 0;
    }
  }

  #history.hidden {
    display: none;
  }

  &.bg-secondary {
    .nav-link {
      &:hover,
      &:focus {
        color: $white !important; // overrides specificity of .link-light
        background-color: $gray-400 !important; // overrides specificity of .link-light
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      &,
      &:hover,
      &:focus {
        color: $nav-pills-link-active-color !important; // overrides specificity of .link-light
        background-color: $nav-pills-link-active-bg !important; // overrides specificity of .link-light
      }
    }

    .sub-link.active,
    .nav-item.show .sub-link {
      &,
      &:hover,
      &:focus {
        background-color: $gray-400 !important; // overrides specificity of .link-light
      }
    }

    .activate-link:focus {
      background-color: transparent !important;
    }

    .dropdown-divider {
      border-width: $border-width;
      border-top-color: $gray-400;
    }
  }

  .ps__thumb-y {
    background-color: $light;
  }

  .search {
    .fa-search {
      color: $gray-200;
    }

    &:hover,
    &:focus {
      .fa-search {
        color: $gray-200;
      }
    }

    .form-control {
      color: $gray-100;
      background-color: transparent;
      border-color: $gray-400;

      &::placeholder {
        color: $gray-200;
      }

      &:hover,
      &:focus {
        background-color: $gray-400;

        &::placeholder {
          color: $gray-200;
        }
      }
    }
  }

  &.sidebar-mini {
    @include sidebar-collapse();

    &:not(.hover) {
    }

    &.hover {
      @include sidebar-expand();

      .nav-item .collapse.show {
        display: block !important;
      }
    }
  }

  &:not(.sidebar-mini) {
  }
}

#offcanvasSidebar {
  --#{$prefix}offcanvas-width: #{$offcanvas-sidebar-width};
  top: $header-height;
}

.sidebar {
  .store-content {
    .avatar {
      @include media-breakpoint-down(md) {
        --#{$app-prefix}avatar-size: #{$avatar-size-sm};
      }
    }
  }
}

#sidebar.sidebar {
  &.sidebar-mini {
    @include media-breakpoint-down(lg) {
      width: 240px;
      transform: translateX(-300px);
      transition: $ease300;
    }

    &.show {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-down(lg) {
    width: 240px;
    height: calc(100% - 90px);
    position: fixed;
    left: 0;
    z-index: 99999;
    top: 90px;
    transform: translateX(-300px);
  }

  &.show {
    transform: translateX(0);
  }

  .store-content {
    a {
      @include media-breakpoint-down(lg) {
        gap: 15px;
        justify-content: flex-start;
      }
    }

    .store-info {
      @include media-breakpoint-down(lg) {
        display: flex;
        opacity: 1;
      }
    }
  }

  #menu-cont {
    @include media-breakpoint-down(lg) {
      max-height: 95%;
      padding-bottom: 30px;
      height: 95%;
      overflow-y: auto !important;
    }

    .ps__rail-x,
    .ps__rail-y {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  #menu {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    > li {
      &:last-child {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    .icon {
      @include media-breakpoint-down(lg) {
        margin-right: 1rem;
      }
    }

    .link-title {
      @include media-breakpoint-down(lg) {
        display: inline-flex;
        opacity: 1;
        overflow: visible;
      }
    }
  }

  .search {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

body.show {
  .navbar-start {
    #headerLeftButtons {
      transform: translateX(0);
    }
  }

  #sidebar {
    &.sidebar {
      transform: translateX(0);

      &.sidebar-mini {
        transform: translateX(0);
      }
    }
  }
}
