.swatches {
  padding: $dropdown-padding-y;
}

.swatch {
  font-size: 100%;
  width: $swatch-size;
  height: $swatch-size;
  border: ($border-width * 2) $border-style var(--#{$prefix}swatch-border);

  &:hover {
    border-color: var(--#{$prefix}swatch-hover-border);
  }

  &.active {
    background-image: escape-svg($swatch-active-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

@each $color, $value in $swatch-variants {
  @include swatch-color($color, $value);
}
