.loading {
  #loading {
    @extend .d-block;
  }

  * {
    cursor: progress;
  }
}
body.overlay {
  &::before {
    position: absolute;
    z-index: 10000;
    height: 100%;
    width: 100%;
    content: ' ';
    background: rgba($gray-500, 0.6);
    // @include backdrop-filter($backdrop-filter-xs);
  }

  &::after {
    content: ' ';
    position: absolute;
    top: calc(50% - $loading-icon-size * ($loading-icon-size-scale * 0.5));
    left: calc(
      50% +
        (
          $loading-icon-size * $loading-icon-size-scale + $grid-gutter-width *
            0.5
        )
    );
    z-index: 10001;
    color: $white;
    --#{$prefix}spinner-width: 2rem;
    --#{$prefix}spinner-height: 2rem;
    --#{$prefix}spinner-vertical-align: -0.125em;
    --#{$prefix}spinner-border-width: 0.25em;
    --#{$prefix}spinner-animation-speed: 0.75s;
    --#{$prefix}spinner-animation-name: spinner-border;
    border: var(--#{$prefix}spinner-border-width) solid;
    border-right-color: transparent;
    display: inline-block;
    width: var(--#{$prefix}spinner-width);
    height: var(--#{$prefix}spinner-height);
    vertical-align: var(--#{$prefix}spinner-vertical-align);
    border-radius: 50%;
    animation: var(--#{$prefix}spinner-animation-speed) linear infinite
      var(--#{$prefix}spinner-animation-name);
  }
}
