// scss-docs-start event-color
@mixin event-color($state, $background) {
  .event-#{$state} {
    $color: color-contrast(opaque($body-bg, $background));
    $hover-bg: mix($color, $background, percentage($table-hover-bg-factor));
    $border-color: #{$background};
    $border-hover-color: mix(
      $color,
      $background,
      percentage($table-border-factor)
    );

    --#{$fc-prefix}event-color: #{$color};
    --#{$fc-prefix}event-bg-color: #{$background};
    --#{$fc-prefix}event-border-color: #{$border-color};
    --#{$fc-prefix}event-hover-color: #{color-contrast($hover-bg)};
    --#{$fc-prefix}event-hover-bg: #{$hover-bg};

    background-color: var(--#{$fc-prefix}event-bg-color);
    color: var(--#{$fc-prefix}event-color);
    border-color: var(--#{$fc-prefix}event-border-color);

    &.fc-list-event:hover td {
      --#{$fc-prefix}list-event-hover-bg-color: #{$hover-bg};
      --#{$fc-prefix}border-color: #{$border-hover-color};

      border-color: var(--#{$fc-prefix}border-color);
    }
  }
}
// scss-docs-end event-color

// scss-docs-start event-dot-color
@mixin event-dot-color($state, $background) {
  .event-#{$state} .fc-list-event-dot {
    $border-color: $background;

    --#{$fc-prefix}event-border-color: #{$border-color};

    border-color: var(--#{$fc-prefix}event-border-color);
  }
}
// scss-docs-end event-dot-color
