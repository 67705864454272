.btn {
  &:only-child {
    margin-left: 0;
  }
}

.btn + .btn,
.btn + .btn-cont,
.btn-cont + .btn,
.btn-cont + .btn-cont {
  margin-left: map-get($spacers, 1);
}

.btn-cont {
  display: inline-block;
}

.btn-link {
  padding-left: 7px;
  white-space: nowrap;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;

  &,
  &:hover,
  &:focus {
    color: currentColor;
  }
}

.table-row .btn {
  margin-left: 3px;
  padding: 3px 10px;
}

@include media-breakpoint-down(sm) {
  .btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
