.fc {
  a,
  a:hover {
    color: $body-color;
  }

  .fc-event-main {
    .fc-event-time + .fc-event-title-container {
      max-width: calc(100% - 50px);
    }

    .fc-event-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
    }

    .fc-event-descr {
      font-weight: normal;
    }
  }

  .fc-h-event,
  .fc-v-event {
    .fc-event-main {
      $color: $body-color;

      --#{$fc-prefix}event-text-color: #{$color};

      color: var(--#{$fc-prefix}event-text-color);
    }
  }

  .fc-day-today {
    background-color: var(--#{$fc-prefix}today-bg-color);
  }

  .fc-scrollgrid-section-body table {
    border-bottom-style: inherit;
  }
}

.fc-event {
  $background: $white;
  $color: $body-color;
  $border-color: $background;

  --#{$fc-prefix}event-color: #{$color};
  --#{$fc-prefix}event-bg-color: #{$background};
  --#{$fc-prefix}event-border-color: #{$border-color};
  --#{$fc-prefix}small-font-size: #{$table-font-size};

  font-size: var(--#{$fc-prefix}small-font-size);
  background-color: var(--#{$fc-prefix}event-bg-color);
  color: var(--#{$fc-prefix}event-color);
  border-color: var(--#{$fc-prefix}event-border-color);

  padding-left: 3px;
  border-left-style: inset;
  border-left-width: 3px;
}

.calendar-navigator {
  cursor: pointer;

  .fc-multimonth-header-table,
  .fc-multimonth-daygrid-table {
    margin-left: 30px;
  }
  .fc-daygrid-week-number {
    left: -30px;
  }
}

.view-small .fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  display: none;
}

@each $color, $value in $swatch-variants {
  @include event-color($color, $value);
}

@each $color, $value in $swatch-colors {
  @include event-dot-color($color, $value);
}
