// scss-docs-start swatch-color
@mixin swatch-color($state, $background) {
  .swatch-#{$state} {
    $color: color-contrast(opaque($body-bg, $background));
    $border: mix($color, $background, percentage($table-border-factor));
    $hover-bg: mix($color, $background, percentage($table-hover-bg-factor));
    $hover-border: mix(
      $color,
      $background,
      percentage($table-border-factor * 2)
    );
    $active-bg: mix($color, $background, percentage($table-active-bg-factor));
    $active-border: mix($color, $background, percentage($table-border-factor));

    --#{$prefix}swatch-color: #{$color};
    --#{$prefix}swatch-bg: #{$background};
    --#{$prefix}swatch-border: #{$border};
    --#{$prefix}swatch-hover-color: #{color-contrast($hover-bg)};
    --#{$prefix}swatch-hover-bg: #{$hover-bg};
    --#{$prefix}swatch-hover-border: #{$hover-border};
    --#{$prefix}swatch-active-color: #{color-contrast($active-bg)};
    --#{$prefix}swatch-active-bg: #{$active-bg};

    color: var(--#{$prefix}swatch-color);
    background-color: var(--#{$prefix}swatch-bg);
    border-color: var(--#{$prefix}swatch-border);
  }
}
// scss-docs-end swatch-color
