.form-control {
  &[readonly] {
    background-color: #e9ecef;
  }

  &:disabled {
    background-color: #e9ecef;
  }
}

.form-group {
  position: relative;

  .form-control {
    color: $gray-500;
  }

  .form-control::placeholder {
    color: $gray-200;
  }

  .form-control::-webkit-input-placeholder {
    color: $gray-200;
  }

  .form-control::-moz-placeholder {
    color: $gray-200;
  }

  .form-control:-ms-input-placeholder {
    color: $gray-200;
  }

  .form-control:-moz-placeholder {
    color: $gray-200;
  }

  .form-switch {
    padding-top: $input-padding-top;
    padding-bottom: $input-padding-top;
  }

  &.disabled {
    .select-clear-btn,
    .select-arrow {
      display: none !important;
    }

    .form-switch .pointer-nodrop {
      margin-left: -1.5em;
    }
  }
}

.form-check-input {
  background-color: #fff;
}

.input-group {
  > [data-groupable='text'] + [data-groupable='text'],
  > [data-groupable='text'] + [data-groupable='button'],
  > [data-groupable='button'] + [data-groupable='text'],
  > [data-groupable='button'] + [data-groupable='button'] {
    margin-left: 3px !important;
    border-radius: var(--mdb-btn-border-radius) !important;
  }

  .select-wrapper {
    flex: 1 1;
  }
}

.select-wrapper {
  @include media-breakpoint-down(sm) {
    min-width: unset !important;
  }
}

.subtab {
  margin-top: -18px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  overflow-x: scroll;
  height: 45px;
  overflow-y: hidden;
  text-align: center;
  margin-bottom: -14px;

  .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

label.full {
  width: auto !important;
  max-width: initial;
}

select.label {
  width: 33% !important;
  border: none;
  padding: 0;
  margin: 2px 4px 0 -14px !important;
  font-size: 13px !important;
  -webkit-appearance: menulist;
}

.tox-statusbar__branding {
  display: none;
}

[key='sign'] canvas {
  border: 1px solid #eaeaea;
  float: left;
  margin-right: 3px;
}

.btn .sub:hover,
.btn.wysihtml-command-active,
.btn .sub.wysihtml-command-active {
  background-color: #4babc1 !important;
  color: rgb(255, 255, 254) !important;
  border-color: transparent !important;
  border-bottom: #4babc1 !important;
  box-shadow: none !important;
}

.btn[value='red']:hover,
.btn.active[value='red'] {
  background-color: $danger;
}

.btn[value='yellow']:hover,
.btn.active[value='yellow'] {
  background-color: $warning;
  color: $gray-400;
}

.btn[value='green']:hover,
.btn.active[value='green'] {
  background-color: $success;
}

.btn .sub:hover .icon {
  color: rgb(255, 255, 254);
}

.btn.multi {
  padding: 0 5px;
  cursor: inherit;
}

.btn.multi .icon {
  margin: 0 3px;
  cursor: pointer;
}

.btn .submenu {
  width: auto;
  margin-left: -10px;
}

.btn .sub {
  padding: 5px 7px;
}

.btn .sub .icon {
  margin-right: 0;
}

.upload {
  display: inline-block;
  position: relative;
}

.upload .btn {
  position: relative;
  z-index: 1;
  height: 100%;
}

.upload .upload {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.field-read.lh-lg {
  max-height: 1000px;
  overflow: scroll;
  max-width: 100%;

  &.read-more {
    overflow: hidden;
    height: 100px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
  }
}

.invalid-feedback {
  right: 0;
}

.stream {
  .btn {
    width: 150px;
  }

  .input-group-text {
    text-align: left;

    @include media-breakpoint-down(md) {
      text-align: left;
      white-space: normal;
    }
  }
}

.form-group {
  .tox {
    &.tox-tinymce {
      max-width: 100%;
    }
  }

  .input-group-text {
    @include media-breakpoint-down(md) {
      text-align: left;
      white-space: normal;
    }

    @include media-breakpoint-down(sm) {
      min-width: unset;
      width: unset;
    }
  }

  canvas {
    &.with-context {
      border: 1px solid $gray-200;
      border-radius: 0.25rem;

      //max-width: 100%;
      @include media-breakpoint-down(xxl) {
        //max-width: 100%;
      }
    }
  }
}

.form-group,
.list-group-item {
  .wikiBtn {
    opacity: 0;
    position: relative;
  }
  > .wikiBtn {
    position: absolute;
    right: 0;
  }

  &:hover .wikiBtn {
    margin: -12px 3px 0 3px;
    font-size: 14px;
    opacity: 1;
  }
}

.d-flex {
  &.split-line {
    column-gap: 10px;
  }
}

.split-line {
  column-gap: 10px;

  @include media-breakpoint-down(md) {
    display: flex !important;
  }
}

.filled {
  ul.ui-sortable {
    padding-left: 2rem;

    .form-check-input[type='checkbox'] {
      margin-right: 0;
    }
  }
}

.view-right {
  .card {
    .list-group {
      ul.ui-sortable {
        li {
          .form-check {
            position: absolute;
            left: 5px;

            .form-check-input {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.table-striped {
  tr {
    td {
      .form-switch {
        padding-left: 1.5em;

        .form-check-input:checked[type='checkbox'] {
          margin-right: 8px;
        }
      }
    }
  }
}

.select-dropdown-container {
  min-width: 200px !important;

  .select-option.select-all-option {
    .select-option-text {
      padding-left: 25px;
      position: relative;

      .form-check-input {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

div[key='salut'] {
  .input-group {
    display: block;
  }
}
