.view {
  > * {
    margin-bottom: $view-margin-y;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .view-header {
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }

  .view-title {
    overflow: hidden;

    @include media-breakpoint-down(md) {
      overflow: visible;
      margin-bottom: 10px;
      margin-left: 10px;
    }

    .warning {
      margin-right: map-get($spacers, 2);
    }

    h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down(md) {
        white-space: normal;
        overflow: visible;
        text-overflow: unset;
      }
    }
  }

  .action-buttons-left {
    @include media-breakpoint-down(md) {
      width: max-content;
      white-space: normal;
      row-gap: 4px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
      column-gap: 5px;
    }

    button {
      @include media-breakpoint-down(md) {
        margin: 0 !important;
      }
    }
  }

  @mixin title-icons-small-devices {
    margin-right: unset !important;
    margin-left: 10px !important;
  }

  .me-auto {
    @include media-breakpoint-down(md) {
      @include title-icons-small-devices;
    }

    @include media-breakpoint-down(sm) {
      @include title-icons-small-devices;
    }
  }

  .action-buttons-right {
    white-space: nowrap;

    @media screen and (max-width: 575.98px), screen and (max-height: 700px) {
      margin-left: 1rem;
    }
  }

  .view-nav {
    max-height: calc(
      100vh - #{$header-height} - #{$view-margin-y} - #{$view-header-height} - #{$view-margin-y}
    );
    margin-top: #{$view-margin-y};
    overflow: hidden;
    overflow-y: auto;
  }

  .view-main {
    > * {
      margin-bottom: $view-margin-y;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .view-right {
    max-width: $view-sidebar-width;
    max-height: calc(
      100vh - #{$header-height} - #{$view-margin-y} - #{$view-header-height} - #{$view-margin-y}
    );
    margin-top: calc(#{$view-header-height} + #{$view-margin-y});
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;

    .read {
      padding: 0;
    }
  }

  &.view-overview {
    .view-right {
      margin-top: calc(#{$view-header-height} + #{$view-margin-y} + 51px);
    }
  }

  &.view-small {
    .action-buttons-left .btn:not(.btn-fix) {
      display: none;
    }

    .view-left,
    .view-right {
      display: none;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.card-minimal {
  .card-body {
    overflow: hidden;
  }
}

.view-body.scrollable,
.card-body.scrollable {
  max-height: calc(100vh - 130px);
}

.view-single {
  .view {
    .view-body {
      overflow-x: hidden;
      height: calc(100vh - 165px);
      overflow-y: scroll;
    }
  }
}

.view-duo {
  .view-overview {
    .view-header {
      min-height: $btn-icon-height;
    }
  }
}
