.dropdown {
  .dropdown-toggle {
    &::after {
      align-self: center;
    }
  }

  & + .btn {
    margin-left: map-get($spacers, 1);
  }
}
