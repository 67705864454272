// Debug
//
// Show debug information as inline elements. Enable with keyboard shortcut (STRG+D)

.admin-info {
  display: none;
  font-family: $font-family-code;
  opacity: 0.65;

  * > &:last-child {
    padding-right: 0 !important;
  }

  th & {
    padding-left: 0 !important;
  }

  .form-label & {
    opacity: 1;
  }
}
.debug-info {
  .admin-info {
    display: inline-block;
  }

  th {
    .admin-info {
      display: block;
    }
  }
}
