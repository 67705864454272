.preview-images {
  flex: 1 0 0%;
  display: inline-block;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  width: 20%;
}

.wiki-preview {
  object-fit: contain;
  height: auto !important;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
}
