.login {
  display: flex;
  align-items: center;
}

#login {
  width: 100%;
  max-width: 340px;
  margin: auto;

  #logo {
    img {
      max-width: 150px;
      max-height: 75px;

      &[src*='.svg'] {
        width: 100px;
        height: 50px;
      }
    }
  }

  .alert {
    &:empty {
      display: none;
    }
  }
}
