main {
  position: absolute;
  top: 54px;
  bottom: 0;
  width: 100%;
  height: calc(100% - 54px);
  overflow: hidden;

  #chat {
    max-width: 60px;
    max-height: 100%;
  }

  #views {
    max-width: calc(100% - 46px);
    max-height: 100%;
    overflow: hidden;
    padding-top: 15px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  #help {
    width: 350px;
    flex-shrink: 0;
    left: 0 !important;

    .card {
      height: 100%;

      .search {
        flex: 1 1 auto;
      }

      #help-detail {
        max-height: calc(100% - 250px);

        img {
          max-width: 100%;
        }

        .field-file {
          max-height: 500px;
          padding-right: 15px;
        }
      }
    }
  }
}
