.link {
  text-decoration: if($link-decoration == none, null, none);

  &:hover:not(i) {
    text-decoration: $link-hover-decoration;
    cursor: pointer;
  }
}
.scrollable {
  max-height: 100%;
  overflow-y: auto !important;
}
.tip {
  cursor: help;
}
.noclick {
  cursor: default !important;
}
.pointer {
  cursor: pointer;
}
.pointer-nodrop {
  cursor: no-drop;
}
.pointer-move {
  cursor: move !important;
}
.lh-lg {
  line-height: 2.5 !important;
}
#tooltip {
  max-width: 400px;
  padding: 7px 14px;
  position: absolute;
  background-color: #132328;
  color: #fff;
  z-index: 100000;
  box-shadow: $btn-box-shadow;
  border-radius: $border-radius;
}
#tooltip img {
  max-width: 100%;
}
.pdf-preview {
  position: absolute;
  z-index: 100000;
  max-width: 75vw;
  max-height: 75vh;
  box-shadow: $btn-box-shadow;
  border-radius: $border-radius;
}

#reclaim_2_document_file img {
  height: 30vh;
}
