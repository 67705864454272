html,
body {
  height: 100%;
}
body {
  overflow: hidden;
}
iframe {
  width: 100%;
}
::-webkit-scrollbar {
  height: 25px;
}
::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 12px;
  background-clip: content-box;
  border: 3px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
