// scss-docs-start avatar-size-mixin
@mixin avatar-size($size) {
  --#{$app-prefix}avatar-size: #{$size};

  width: var(--#{$app-prefix}avatar-size);
  height: var(--#{$app-prefix}avatar-size);
}
// scss-docs-end avatar-size-mixin

// scss-docs-start store-avatar-mixin
@mixin store-avatar($color: $light) {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  @include box-shadow(0 0 0 0.125rem rgba($color, 0.25));

  img {
    max-height: 100%;
  }
}
// scss-docs-end store-avatar-mixin
