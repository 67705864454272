//
// Base styles
//

.avatar {
  display: inline-block;
  text-align: center;
  @extend .rounded-circle;

  img {
    color: $text-muted;
    max-height: 100%;
    @include font-size($font-size-sm);
  }
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  background-color: $light;
}

//
// Avatar Sizes
//

.avatar-xl {
  @include avatar-size($avatar-size-xl);

  .avatar-title {
    @include font-size($font-size-lg);
  }
}

.avatar-lg {
  @include avatar-size($avatar-size-lg);

  .avatar-title {
    @include font-size($font-size-lg);
  }
}

.avatar-md {
  @include avatar-size($avatar-size-md);

  .avatar-title {
    @include font-size($font-size-lg);
  }
}

.avatar-sm {
  @include avatar-size($avatar-size-sm);
  float: left;

  .avatar-title {
    @include font-size($font-size-sm);
  }
}

.avatar-xs {
  @include avatar-size($avatar-size-xs);

  .avatar-title {
    @include font-size($font-size-sm);
  }
}

.bg-white {
  .avatar {
  }

  .avatar-title {
    color: $text-muted;
  }

  .dropdown-toggle {
    &:hover,
    &:focus {
      .avatar {
        background-color: $gray-500;
        @include box-shadow(0 0 0 0.25rem rgba($light, 0.25));

        img {
          opacity: 0.8;
        }
      }
    }
  }
}

.bg-secondary {
  .avatar {
    background-color: $white;
  }

  .avatar-title {
    color: $secondary;
  }
}

// Todo: Remove when avatar class and size are added to company logo in view content (APP-689)
.view {
  [id*='logo_file'],
  [id*='pic_file'] {
    img {
      @extend .avatar-xl;
    }
  }
}
