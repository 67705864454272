#dashboard {
  max-width: calc(100% - 46px);
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
  .row {
    max-height: calc(100vh - 25px);
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      max-height: calc(100vh - 190px);
    }
    @include media-breakpoint-down(md) {
      max-height: calc(100vh - 65px);
    }

    .col {
      &:last-child {
        @include media-breakpoint-down(lg) {
          margin-bottom: 100px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 40px;
        }
      }
    }
  }

  .card {
    height: 100%;
    @include media-breakpoint-down(md) {
      //height: auto;
    }
    &:last-child {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
    .table-scroll {
      max-height: calc(30rem);
    }
  }
  .chart {
    max-height: 500px;
  }
  .diagram {
    width: 450px;
    height: 223px;
    overflow: hidden;
  }
}
