.view-main > nav {
  overflow: hidden;

  .nav-tabs {
    flex-wrap: nowrap;

    .icon {
      padding: $nav-link-padding-y 0;
    }
  }
}
.nav-pills {
  &.flex-column {
    margin-left: 0;

    .nav-link {
      color: currentColor;
      margin: 0;

      &:hover,
      &:focus {
        background-color: $gray-100;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: $gray-100;

      &,
      &:hover,
      &:focus {
        color: currentColor;
      }
    }
  }
}
.view-left .nav-link .icon {
  margin-left: 1rem;
}
.sub-menu {
  @extend .nav;

  &:empty {
    display: none;
  }

  .collapse > &,
  .collapsing > & {
    margin-top: map-get($spacers, 1);
  }
}

.sub-link {
  @extend .nav-link;
  padding-left: $sub-link-offset !important;
}

@include media-breakpoint-down(md) {
  .view-main > nav {
    margin-bottom: 0 !important;

    .nav-tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 0;
      button.nav-link {
        white-space: nowrap;
      }
    }
    .nav-tabs::-webkit-scrollbar {
      display: none;
    }
  }
}
