.modal {
  .modal-dialog {
    margin-right: auto;
    margin-left: auto;

    &.width-sidebar {
      padding-right: 260px;
    }

    .modal-content {
      overflow: inherit;

      > .card {
        margin-bottom: 0 !important;

        .card-title {
          display: none;
        }
      }

      .modal-sidebar {
        position: absolute;
        background-color: $white;
        padding: 0.75rem;
        border-radius: calc(0.5rem - 1px);
        right: -270px;
        width: 260px;
        max-height: 100%;
        overflow-y: scroll;
      }

      .modal-footer {
        justify-content: initial;
      }
    }
  }
}

.modal-dialog-scrollable {
  .modal-body {
    background-color: $body-bg;
  }
}

.modal-dialog-no-scrollable {
  .modal-body {
    overflow-y: hidden;

    > .card {
      > .card-body {
        overflow-y: hidden;
      }
    }
  }
}

.modal-file object,
.modal-file image {
  height: 84vh;
}

.modal-header {
  @include media-breakpoint-down(md) {
    display: block !important;

    .btn {
      --mdb-btn-padding-top: 0.1rem !important;
      --mdb-btn-padding-bottom: 0.1rem !important;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-left: 12px !important;
    padding-right: 12px !important;

    .btn {
      --mdb-btn-padding-x: 1rem !important;
    }
  }
}

.modal-xs {
  min-width: 20vw !important;
}

.modal-sm {
  min-width: 60% !important;
  max-width: 65% !important;
}

.modal-md {
  min-width: 75% !important;
  max-width: 80% !important;
}

.modal-lg {
  min-width: 80% !important;
  max-width: 85% !important;
}

.modal-xl {
  min-width: 85% !important;
  max-width: 90% !important;
}

.modal-xxl {
  min-width: 90% !important;
  max-width: 95% !important;
}
