.list-group {
  ul {
    margin-bottom: 1rem;
  }
}

.list-group-item {
  background-color: white;

  &.h5 {
    border: none;
    margin-bottom: 0;
  }

  > * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  img {
    max-height: 250px;
  }
}
