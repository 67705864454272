.badge {
  &:empty:not(.badge-notification) {
    display: inline-block;
  }

  &:not(.badge-notification) {
    width: 1em;
    height: 1em;
    padding: 0;
    vertical-align: middle;
  }
}

.color:not(input) {
  display: inline-block;
  width: 1em;
  height: 1em;
  @include border-radius($border-radius-pill);
}
