.card {
  margin-bottom: $view-margin-y;

  &:last-child {
    margin-bottom: 0;
  }

  &.card-minimal {
    height: 70px;
    overflow: hidden;
  }

  &.card-sticky {
    max-height: 25vh;
    overflow-y: scroll;
  }
}

.search .card {
  min-width: 414px;
  width: calc(33% - 19px);
  margin: 0 25px 25px 0;
  overflow-x: hidden;
}
