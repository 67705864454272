// necessary due to hard-coded padding in MDB form control
.form-control {
  padding-top: $input-padding-y !important;
  padding-bottom: $input-padding-y !important;
  border: 1px solid $gray-200 !important;

  &:focus {
    border-color: $tertiary !important;
    background-color: var(--mdb-form-search-bg);
    box-shadow: inset 0px 0px 0px 1px $tertiary !important;
  }
}

.form-outline .form-control:focus {
  box-shadow: inset 0px 0px 0px 1px $tertiary !important;
}
